var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Dropdown',_vm._b({on:{"change":_vm.updateValue}},'Dropdown',{
      ..._vm.$props,
      value: _vm.newValue,
      options: _vm.options,
      returnObject: true,
      itemText: 'SeekDisplay',
      itemValue: 'key'
    },false)),(_vm.footnote)?_c('p',{staticClass:"footnote mb-2",domProps:{"innerHTML":_vm._s(_vm.footnote)}}):_vm._e(),(_vm.newValue && _vm.agent && _vm.newValue.BranchName != _vm.agent.BranchName)?_c('v-alert',{key:"HomeBranch",staticClass:"my-2",attrs:{"dense":"","outlined":"","type":"warning","icon":"fal fa-exclamation-triangle"}},[_vm._v(" Warning: Policy will be issued under the selected Agent's Assigned Branch ("+_vm._s(_vm.newValue.BranchName)+"). ")]):_vm._e(),(_vm.invalidAgent)?_c('v-alert',{key:"invalidAgent",staticClass:"my-2",attrs:{"dense":"","type":"error"}},[_vm._v(" The previously selected Agent of Record is no longer available. Please select a different agent from the list. ")]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }