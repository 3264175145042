<template>
  <div>
    <Dropdown 
      v-bind="{
        ...$props,
        value: newValue,
        options,
        returnObject: true,
        itemText: 'SeekDisplay',
        itemValue: 'key'
      }"
      @change="updateValue"
    >
    </Dropdown>
    <p class="footnote mb-2" v-if="footnote" v-html="footnote"></p>

     <v-alert dense outlined type="warning" v-if="newValue && agent && newValue.BranchName != agent.BranchName" 
      icon="fal fa-exclamation-triangle" key="HomeBranch"
      class="my-2">
      Warning: Policy will be issued under the selected Agent's Assigned Branch ({{newValue.BranchName}}).
    </v-alert>

    <v-alert dense type="error" v-if="invalidAgent" class="my-2" key="invalidAgent">
      The previously selected Agent of Record is no longer available. Please select a different agent from the list.
    </v-alert>
  </div>
</template>
<script>
import Dropdown from '@/components/form/Dropdown.vue'
import { AgentService } from '@/api/AgentService';

export default {
  name: "AgentOfRecordDropdown",
  components: {
    Dropdown
  },
  data() {
    return {
      newValue: null,
      invalidAgent: false,
      footnote: 'You must select an Agent of Record who is licensed in the state of the risk in order to bind business.',
      options: [],
      agent: {}
    }
  },
  props: {
    value: Object,
    rules: String,
    label: String,
    clearable: Boolean,
    required: Boolean,
    errors: Array,
    disabled: Boolean,
    name: String,
    module: String
  },
  emits: [
      'change'
  ],
  methods: {
      updateValue(v) {
        if (v) {
          this.newValue = v;
        }
        else {
          this.newValue = null;
        }
        this.invalidAgent = false;
        this.emitValue();
      },
      emitValue() {
          this.$emit('change', this.newValue)       
      },
  },
  computed: {
  },
  watch: {
      value: {
          immediate: true,
          deep: true,
          handler(newVal) {
            this.newValue = newVal;
          }
      }
  },
  async mounted() {
    this.agent = this.$store.getters.getControlValue(this.module, 'Agent');
    const agency = this.$store.getters.getControlValue(this.module, 'Agency');
    const risk = this.$store.getters.getControlValue(this.module, 'Risk');

    this.options = await AgentService.find({
      agencyId: agency.id,
      producer: true,
      license: risk.State
    })

    if (this.newValue) {
      if (!this.options.find(o => this.newValue.id == o.id && o.Producer)) {
        this.newValue = null;
        this.emitValue();
        this.invalidAgent = true;
      }
    }

    const agentInList = this.options.find(o => this.agent.id == o.id && o.Producer);
    if (agentInList) {
      if (!this.newValue) {
        this.newValue = agentInList;
        this.emitValue();
      }
    } else {
      this.footnote += '<br><strong>No ' + risk.State + ' License Info on file for - <em>' + this.agent.SeekDisplay + '</em>. Please update the profile.</strong>'
    }

  }
}
</script>